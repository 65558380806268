import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const PrivacyPage = () => (
  <Layout>
    <SEO title="Privacy" />
    <h1>Privacy Statement</h1>

    <p>
      We're sure you would like your contact details kept private and that's exactly
      what we'll do; we'll only use them to keep in touch with you. If, for whatever
      reason, we would like to pass on or publish any of your contact details, we
      will always ask your permission first and tell you exactly how they will be
      used, and you have the right to withhold your permission and maintain your
      privacy.
    </p>

    <p>
      Information Commissioner's Office Data Controller registration number: Z1931009.
    </p>
  </Layout>
)

export default PrivacyPage
